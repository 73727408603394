// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-brainless-src-templates-all-pages-js": () => import("./../../../node_modules/gatsby-theme-brainless/src/templates/all-pages.js" /* webpackChunkName: "component---node-modules-gatsby-theme-brainless-src-templates-all-pages-js" */),
  "component---node-modules-gatsby-theme-brainless-src-templates-brain-js": () => import("./../../../node_modules/gatsby-theme-brainless/src/templates/brain.js" /* webpackChunkName: "component---node-modules-gatsby-theme-brainless-src-templates-brain-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-entanglement-js": () => import("./../../../src/pages/entanglement.js" /* webpackChunkName: "component---src-pages-entanglement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-all-tags-js": () => import("./../../../src/templates/all-tags.js" /* webpackChunkName: "component---src-templates-all-tags-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-single-tag-js": () => import("./../../../src/templates/single-tag.js" /* webpackChunkName: "component---src-templates-single-tag-js" */)
}

